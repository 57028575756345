/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'eye_close': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<mask id="svgicon_eye_close_a" maskUnits="userSpaceOnUse" x="0" y="8" width="18" height="6"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.012 12.528l.008.004 1.39.591.008.003c.372.135.749.256 1.132.358l.009.002 1.485.3.008.001c.392.058.785.098 1.181.121h1.535c.395-.023.79-.063 1.181-.12l.008-.002 1.486-.3.01-.002a13.525 13.525 0 001.13-.358l.007-.003 1.39-.59.008-.005a13.04 13.04 0 002.645-1.76c.46-.394.446-1.081.03-1.486-.407-.393-1.036-.38-1.448-.029-4.118 3.513-10.312 3.513-14.43 0-.411-.351-1.04-.364-1.447.03-.417.404-.43 1.091.03 1.486.82.704 1.709 1.29 2.644 1.76zm4.12.967z" _fill="#07F"/></mask><g mask="url(#svgicon_eye_close_a)"><path pid="1" _fill="#fff" d="M0 0h18v18H0z"/></g>'
  }
})
